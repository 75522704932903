import Glide from '@glidejs/glide';

function init() {
    const elements = document.querySelectorAll('.marquee');
    
    if (elements.length) {
		Array.from(elements).forEach(element => {
			let perView = element.getAttribute('data-glide-perView') || 1;
			let len = element.getAttribute('data-glide-count');

			if (!len) {
				const slides = element.querySelectorAll(':scope .glide__slide');
				len = slides.length && slides.length;
			}

			const glide = new Glide(element, {
				type: 'carousel',
				start: 0,
				perView: perView,
                gap: 0,
                autoplay: 5000,
                hoverpause: true
            }).mount();
        });
    }
}
init();