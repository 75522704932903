import 'bootstrap/js/dist/dropdown';

function showSalesRep(id) {
	$('.sales-reps__content-container').removeClass('d-none');
	$('.sales-reps__selected-display').text(salesRepRegions[id].name);
	$('.sales-rep:not(.d-none)').addClass('d-none');
	$('.sales-rep.term-' + id).removeClass('d-none');
}

jQuery(document).ready($ => {

	// Convert dropdowns to open on hover instead of click
	$(document).on('mouseenter mouseleave', '.dropdown', e => {
		const _d = $(e.target).closest('.dropdown');
		const expand = e.type === 'mouseenter';

		_d[expand ? 'addClass' : 'removeClass']('show');
		_d.find('.dropdown-toggle').attr('aria-expanded', expand);
	});

	if ($.fn.select2) {
		$('.sales-reps__facet--search').select2({
			dropdownParent: $('.sales-reps__filter-left'),
			minimumInputLength: 2
		}).val(null).trigger('change');

		$('.sales-reps__facet--search').on('select2:open', function() {
			// There are some instances where the dropdown doesn't position correctly, so
			// reposition after open using time as it doesn't seem to work without it.
			var $this = $(this);
			setTimeout(function() {
				$this.data('select2').dropdown._positionDropdown();
			}, 10);
		});

		$('.sales-reps__facet--search').on('select2:select', function(e) {
			showSalesRep(e.params.data.id);
			// When search is used, clear out the select fields.
			$('.sales-reps__facet--select').val(null).trigger('change').next().removeClass('touched');
			$('.sales-reps__facet-container:gt(0)').addClass('d-none');
			$(this).next('.select2-container').addClass('touched');
		});

		$('.sales-reps__facet--select').eq(0).select2({
			dropdownParent: $('.sales-reps__facet--select').eq(0).parent(),
			minimumResultsForSearch: Infinity
		}).val(null).trigger('change');

		$('.sales-reps__facet--select').on('select2:select', function(e) {
			// Clear the value from the search filter.
			$('.sales-reps__facet--search').val(null).trigger('change').next().removeClass('touched');
			$(this).next('.select2-container').addClass('touched');
			// Hide any showing sales reps in case higher level selection changed.
			$('.sales-reps__content-container').addClass('d-none');
			var index = $(this).index('.sales-reps__facet--select');
			// If this isn't the last select element, get the child terms
			// and populate the next select field.
			if (index !== ($('.sales-reps__facet--select').length - 1)) {
				var children = _.where(salesRepRegions, {
					parent: parseInt(e.params.data.id)
				});
				var data = _.sortBy(_.map(children, function(val, key) {
					return {
						id: val.id,
						text: val.name
					};
				}), 'text');

				// Hide subsequent fields.
				$(this).parent().nextAll().addClass('d-none');
				if (data.length) {
					// Clear out the options and add the new data set.
					$(this).parent().next().removeClass('d-none').children('.sales-reps__facet--select').html('').select2({
							dropdownParent: $(this).parent(),
							data: data,
							minimumResultsForSearch: Infinity
						})
						.val(null)
						.trigger('change')
				}
			}
			// If there are no more selections to be made, show the sales rep.
			if ((!children || !children.length) && typeof e.params.data.id !== 'undefined') {
				showSalesRep(e.params.data.id);
			}
		});

		$('#contact-reason').select2({
			dropdownParent: $('#contact-reason').parent(),
			minimumResultsForSearch: Infinity,
			width: '100%'
		});
	}
});
