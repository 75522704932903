import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/collapse';

$(() => {
	// Since FacetWP reloads with ajax, re-initialize.
	$(document).on('init.modal', () => {
		const $player = $('#video-player');

		$player.on('show.bs.modal', function(event) {
			const modal = $(this);
			const source = $(event.relatedTarget); // Button that triggered the modal.
			const video = source.data('html');
			const description = source.data('description');
			const title = source.find('.post__title').text();

			const body = `<div class="embed-responsive embed-responsive-16by9">${video}</div>`;

			modal.find('.modal-title').text(title);
			modal.find('.modal-body').html(body + description);
		});

		$player.on('hide.bs.modal', function(event) {
			const modal = $(this);

			modal.find('.modal-title').text('');
			modal.find('.modal-body').html('');
		});

	}).trigger('init.modal');
});
