(async () => {
	function placeholderPolyfill() {
		this.classList[this.value ? 'remove' : 'add']('placeholder-shown');
	}

	Array.from(document.querySelectorAll('[placeholder]')).forEach(el => {
		el.addEventListener('keyup', placeholderPolyfill);

		if (el.value === '') {
			placeholderPolyfill.call(el);
		}
	});
})();