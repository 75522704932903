document.addEventListener('DOMContentLoaded', () => {
	const navbar = document.querySelector('#mobile-nav');
	const container = document.querySelector('#mobile-nav-bottom');

	navbar.querySelector('.navbar-toggler').addEventListener('click', e => {
		const target = e.currentTarget;
		const backdrop = document.querySelector('.page-backdrop');
		const isExpanded = target.getAttribute('aria-expanded').toString();

		if (isExpanded === 'false') {
			backdrop.classList.add('show');

		} else {
			backdrop.classList.remove('show');
		}

	}, false);

	Array.from(container.querySelectorAll('.parent-link')).forEach(link => {
		link.addEventListener('click', e => {
			const target = e.target;
			const parentId = target.getAttribute('data-id');

			// Find active sub
			const active = container.querySelector('ul.active');

			// Find child sub navigation
			const next = container.querySelector(`[data-parent="${parentId}"]`);

			active.classList.remove('active');
			next.classList.add('active');

			setTimeout(() => {
				container.classList.add('show-sub');
			}, 0);

		}, false);
	});

	Array.from(container.querySelectorAll('.back-link')).forEach(link => {
		link.addEventListener('click', () => {
			container.classList.remove('show-sub');
		}, false);
	});
});