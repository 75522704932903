/* global document */
import pEvent from 'p-event';

(async () => {
	const form = document.querySelector('#contact-form');

	if (form) {
		const formButton = document.querySelector('#footer-contact-btn');
		const wpcf7 = form.querySelector('.wpcf7');

		// Add click event to initial button in footer contact area
		formButton.addEventListener('click', async event => {
			const btn = event.target;
			const iframe = form.querySelector('iframe');

			// Fade button
			btn.classList.add('fade');

			// Wait for button to fade
			await pEvent(btn, 'transitionend');

			// Hide button
			btn.parentNode.removeChild(btn);

			// Show iframe before expanding so that we can get the height
			if (iframe) {
				iframe.classList.add('show');
			}

			// Move and expand form
			form.style.height = `${form.scrollHeight}px`;

			// Wait for form to expand
			await pEvent(form, 'transitionend');

			// Fade in form
			form.classList.add('show');
			form.style.height = 'auto';
		}, false);

		// Listen for a successful submission
		if (wpcf7) {
			form.querySelector('.wpcf7').addEventListener('wpcf7mailsent', async () => {
				const parent = document.querySelector('.footer-contact');
				const heading = parent.querySelector('.heading-component');
				const fields = parent.querySelector('.contact-form-fields');
				const msg = parent.querySelector('.contact-form-msg');

				// Hide previous message
				msg.classList.remove('show');

				// Set initial height
				heading.style.height = `${heading.scrollHeight}px`;
				fields.style.height = `${fields.scrollHeight}px`;

				// Fade out
				heading.classList.add('fade');
				fields.classList.remove('show');

				await pEvent(fields, 'transitionend');

				// Slide up
				heading.style.height = '0px';
				fields.style.height = '0px';

				await pEvent(fields, 'transitionend');

				// Remove
				heading.remove();
				fields.remove();

				// Now show message
				msg.classList.add('success');
				msg.classList.add('show');
			}, false);
		}
	}
})();
