jQuery(document).ready(function($) {
	const {FWP, wp} = window;

	if (wp && wp.hooks) {
		wp.hooks.addFilter('facetwp/selections/date_range', function(choices, args) {
			// Change date range format for current selection display.
			if (/from.*to/.test(choices)) {
				return choices.replace(/from (.*) to (.*)$/, '$1 - $2');
			} else {
				return choices.replace(/(from|to) (.*)$/, '$2');
			}
		});

		wp.hooks.addFilter('facetwp/selections/fselect', function(choices, args) {
			// Remove empty facet from current selections display.
			$.each(choices, function(idx, val) {
				if (!val.label.length) {
					choices.splice(idx, 1);
				}
			});

			return choices;
		});

		wp.hooks.addFilter('facetwp/set_options/date_range', function(flatpickr_opts, args) {
			flatpickr_opts.onOpen = function(selectedDates, dateStr, instance) {
				$('.flatpickr-calendar').addClass('custom');
				$('.flatpickr-weekday').each(function() {
					$(this).text(function() {
						return $(this).text().trim()[0];
					});
				});
				$('.flatpickr-day').each(function() {
					$(this).html(function() {
						return '<strong>' + $(this).text() + '</strong>';
					});
				});
			}

			flatpickr_opts.onMonthChange = function(selectedDates, dateStr, instance) {
				$('.flatpickr-weekday').each(function() {
					$(this).text(function() {
						return $(this).text().trim()[0];
					});
				});
				$('.flatpickr-day').each(function() {
					$(this).html(function() {
						return '<strong>' + $(this).text() + '</strong>';
					});
				});
			}

			// Set the minimum date for the end date on load.
			if ($(args.element).hasClass('facetwp-date-max') && FWP.facets.event_date[0]) {
				flatpickr_opts.minDate = FWP.facets.event_date[0];
				flatpickr_opts.maxDate = null;
			}

			// Set the max date for the start date on load.
			if ($(args.element).hasClass('facetwp-date-min') && FWP.facets.event_date[1]) {
				flatpickr_opts.minDate = null;
				flatpickr_opts.maxDate = FWP.facets.event_date[1];
			}

			return flatpickr_opts;
		});

		$(document).on('facetwp-loaded', function() {
			// When reloading ajax results, trigger event
			$(this).trigger('init');

			// Since FacetWP reloads with ajax, add class for "selected" styles
			$('.facetwp-facet select.hidden').each(function() {
				if ($(this).val() && $(this).val().length) {
					$(this).closest('.fs-wrap').children('.fs-label-wrap').addClass('touched');
				}
			});

			$('.facetwp-date[type="hidden"]').each(function() {
				if ($(this).val()) {
					$(this).next().addClass('touched');
				}
			});

			// Show the clear all facets button if anything is selected.
			if (FWP.build_query_string().length) {
				$('.facet-reset').show();
			} else {
				$('.facet-reset').hide();
			}
		});
	}
});
