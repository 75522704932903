import Glide from '@glidejs/glide';
import debounce from 'lodash-es/debounce';

function getPerView() {
	if (window.matchMedia("(min-width: 992px)").matches) {
		return 3;

	} else if (window.matchMedia("(min-width: 768px)").matches) {
		return 2;

	} else {
		return 1;
	}
}

function init() {
	const elements = document.querySelectorAll('.glide');

	if (elements.length) {
		Array.from(elements).forEach(element => {
			let perView = element.getAttribute('data-glide-perView') || 3;
			let len = element.getAttribute('data-glide-count');

			if (!len) {
				const slides = element.querySelectorAll(':scope .glide__slide');
				len = slides.length && slides.length;
			}

			const mediaPerView = getPerView();

			if (perView > mediaPerView) {
				perView = mediaPerView;
			}

			const glide = new Glide(element, {
				type: 'carousel',
				start: 0,
				perView: perView,
				gap: 0

			}).mount();

			window.addEventListener('resize', debounce(e => {
				const currentPerView = glide.settings.perView;
				const mediaPerView = getPerView();
				let newPerView;
				let isDisabled = false;

				if (currentPerView === mediaPerView) {
					return;
				}

				newPerView = mediaPerView;

				// Disable if there aren't enough slides
				if (newPerView >= len) {
					newPerView = len;
					isDisabled = true;
				}

				glide.update({
					perView: newPerView
				});

				glide[(isDisabled ? 'disable' : 'enable')]();
				element.setAttribute('aria-enabled', !isDisabled);

			}, 250), false);

			// Trigger resize event
			if (typeof(Event) === 'function') {
				// Modern browsers
				window.dispatchEvent(new Event('resize'));

			} else {
				const evt = window.document.createEvent('UIEvents');

				evt.initUIEvent('resize', true, false, window, 0);
				window.dispatchEvent(evt);
			}
		});
	}
}

init();